<template>
  <b-container fluid>
    <bo-page-title />
    <div class="dashboard">
      <div class="row">
        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
          <div class="card">
              <div class="card-body">
                <h5 class="card-title">Howdy</h5>
                <br> Welcome to the <strong></strong> Website Administrator Page
                <br>You are on the PROTECTED page of the <strong></strong> Website Content Management
                system.<br><br>
                <p> This page is specifically intended ONLY for website administrators who will manage content.<br>
                    Before making arrangements, there are few things to note: <br><br>

                    1. Save and secure your Username and Password to avoid undesirable things.<br>
                    2. Prepare the material or content that will be updated first.<br>
                    3. Also prepare photos, videos or other related material, to facilitate the updating process.<br>
                    4. If there are questions about using features in this backoffice, you can ask the web developer
                    contact below.<br>
                    5. Use the latest version of the browser to get the compatibility features in this backoffice.<br>
                    <p>
                      Web Developer Contact : Lingkar 9<br>
                      Email : hello@lingkar9.com<br>
                      Phone : 0819 3096 2159
                    </p><br />
              </div>
          </div>
        </div>
      </div>
    </div>
  </b-container>
</template>
<script>
import GlobalVue from '../../libs/Global.vue'

export default {
  extends: GlobalVue,
  data() {
    return {
    }
  },
  computed: {
  },
  methods: {
  },
	components: {
	},
  mounted() {
    this.apiGet()
  },
  watch:{
    $route(){
      this.apiGet()  
    }
  },
}
</script>